import * as React from 'react';
import {faExclamationTriangle} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {ContentModal} from '../../ui/modals/ContentModal/ContentModal';
import {formatCurrency} from '../../formatCurrency';
import {OrderHeader} from '../order-header.class';
import {TableCards} from '../../ui/Tables/TableCards';
import {TemplateHelpers} from '../../tools/template-helpers.class';
import {User} from '../../users/user.class';
import Button from '../../ui/Buttons/Button';

interface OpenOrdersModalProps {
    onClose: () => void;
    onSelectOrder: (orderOption: string) => void;
    orderHeaders: OrderHeader[];
    show: boolean;
    user: User;
}

export const OpenOrdersModal = ({onClose, onSelectOrder, orderHeaders, show, user}: OpenOrdersModalProps) => {
    /**
     * Closes modal without selecting an order
     */
    const cancelOrderSelection = () => {
        onSelectOrder(``);
        onClose();
    };

    /**
     * Template
     */
    return (
        <ContentModal
            onClose={cancelOrderSelection}
            footer={
                <>
                    <Button
                        modal={true}
                        modalOrderChangeButton1={true}
                        onClick={() => {
                            onClose();
                        }}
                        size="smToLg"
                        variant="outline-primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        e2e="manageYourOrderPopup-CreateOrderBtn"
                        modal={true}
                        modalOrderChangeButton2={true}
                        onClick={() => {
                            onSelectOrder(`createNewOrder`);
                            onClose();
                        }}
                        size="smToLg"
                        variant="primary"
                    >
                        Create New Order
                    </Button>
                </>
            }
            show={show}
            title="Manage Your Orders"
        >
            <>
                <h4>Edit an existing order</h4>
                <TableCards
                    columns={[
                        {
                            title: 'Order',
                            customFormatter: (orderHeader: OrderHeader) => (
                                <>
                                    <Button
                                        onClick={() => {
                                            onSelectOrder(orderHeader.ordrNbr);
                                            onClose();
                                        }}
                                        size="sm"
                                        variant="link"
                                    >
                                        {orderHeader.ordrNbr}
                                    </Button>
                                    {orderHeader.status === `Order Declined` && (
                                        <>
                                            <br />
                                            <span className="tw-text-red-600">
                                                <FontAwesomeIcon icon={faExclamationTriangle} />
                                                Declined
                                            </span>
                                        </>
                                    )}
                                </>
                            ),
                        },
                        {
                            title: 'Order Date',
                            customFormatter: (orderHeader: OrderHeader) => TemplateHelpers.formatDate(orderHeader.ordrDt),
                        },
                        {
                            title: 'PO',
                            maskPII: true,
                            fieldName: 'po',
                        },
                        {
                            title: 'Location',
                            maskPII: true,
                            fieldName: 'ordLocation',
                        },
                        {
                            title: 'Attention',
                            maskPII: true,
                            fieldName: 'attn',
                        },
                        {
                            title: 'City',
                            fieldName: 'city',
                        },
                        {
                            title: 'State',
                            fieldName: 'st',
                        },
                        {
                            title: 'Amount',
                            hidden: !user.canViewPrice(),
                            rightAlign: true,
                            customFormatter: (orderHeader: OrderHeader) => formatCurrency(orderHeader.amount),
                        },
                    ]}
                    data={orderHeaders}
                />
            </>
        </ContentModal>
    );
};
