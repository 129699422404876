import * as React from 'react';
import * as yup from 'yup';
import {FormProvider, useForm} from 'react-hook-form';
import {useRef, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';

import {Alerts} from '../../ui/Alerts/Alerts';
import {ContentModal} from '../../ui/modals/ContentModal/ContentModal';
import {Input} from '../../ui/forms/Input/Input';
import {UsersService} from '../../../client/users/users.service';
import {useService} from '../../react/ServiceContext';
import {validateAccountNum} from '../../tools/yup-validators.functions';
import Button from '../../ui/Buttons/Button';

interface AccountEntryForm {
    accountEntry: string;
}

interface AccountEntryModalProps {
    onHide: () => void;
    returnUrl?: string;
    show: boolean;
}

export const AccountEntryModal = ({onHide, returnUrl, show}: AccountEntryModalProps) => {
    const [errorMessage, setErrorMessage] = useState(``);
    const formRef = useRef(null);
    const usersService: UsersService = useService(`usersService`);

    // Setup form
    const accountEntryFormValidation = yup.object({
        accountEntry: validateAccountNum(true),
    });
    const useFormReturn = useForm({mode: `onSubmit`, resolver: yupResolver(accountEntryFormValidation)});

    /**
     * Handles account entry form submission
     */
    const submitAccountEntryForm = (accountEntryForm: AccountEntryForm) => {
        setErrorMessage(``);

        // Replace order detail URLs with /orders to avoid auto account switching
        const currentUrl = `${location.pathname}${location.search}`;
        if (returnUrl?.startsWith(`/orders/`) || currentUrl.startsWith(`/orders/`)) {
            returnUrl = `/orders`;
        }

        // Perform selectAccount
        usersService.selectAccount(null, null, returnUrl || currentUrl, accountEntryForm.accountEntry);
    };

    /**
     * Submits form
     */
    const submitForm = () => {
        if (formRef.current) {
            formRef.current.dispatchEvent(new Event(`submit`, {bubbles: true, cancelable: true}));
        }
    };

    /**
     * Template
     */
    return (
        <ContentModal
            footer={
                <>
                    <Button
                        modal={true}
                        modalOrderChangeButton1={true}
                        onClick={onHide}
                        size="smToLg"
                        variant="outline-secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        modal={true}
                        modalOrderChangeButton2={true}
                        onClick={submitForm}
                        size="smToLg"
                        type="submit"
                        variant="secondary"
                    >
                        Login
                    </Button>
                </>
            }
            onClose={onHide}
            show={show}
            title="Account Login"
        >
            <Alerts
                message={errorMessage}
                variant="danger"
            />
            <FormProvider {...useFormReturn}>
                <form
                    onSubmit={(event) => {
                        useFormReturn
                            .handleSubmit(submitAccountEntryForm)(event)
                            .catch((handleSubmitErr) => {
                                setErrorMessage(handleSubmitErr.message);
                            });
                    }}
                    ref={formRef}
                >
                    <p>Enter the customer's account number</p>
                    <Input
                        autoFocus={true}
                        error={useFormReturn?.formState?.errors?.accountEntry?.message as string}
                        label="Account Number"
                        name="accountEntry"
                        {...useFormReturn.register(`accountEntry`)}
                    />
                </form>
            </FormProvider>
        </ContentModal>
    );
};
