import * as React from 'react';
import {ReactElement} from 'react';

import {ContentModal} from '../ContentModal/ContentModal';
import Button from '../../Buttons/Button';

interface InfoModalProps {
    headerText?: string;
    message: string | ReactElement;
    modalTitle?: string;
    msgAsHTML?: boolean;
    onClose: () => void;
    show: boolean;
    showOkButton?: boolean;
}

export const InfoModal = ({headerText, message, modalTitle, msgAsHTML, onClose, show, showOkButton}: InfoModalProps) => (
    <ContentModal
        footer={
            <>
                {showOkButton && (
                    <Button
                        modal={true}
                        onClick={onClose}
                        size="smToLg"
                        variant="secondary"
                    >
                        Close
                    </Button>
                )}
            </>
        }
        onClose={onClose}
        show={show}
        title={modalTitle}
    >
        <>
            {headerText && <h4>{headerText}</h4>}
            {msgAsHTML ? <div dangerouslySetInnerHTML={{__html: message}}></div> : <div>{message}</div>}
        </>
    </ContentModal>
);
