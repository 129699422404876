import * as React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {FormProvider, useForm} from 'react-hook-form';
import {useRef, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';

import Button from '../../ui/Buttons/Button';
import {ContentModal} from '../../ui/modals/ContentModal/ContentModal';
import {Input} from '../../ui/forms/Input/Input';
import {NetResultsService} from '../../../client/net-results/net-results.service';
import {ProgressOverlay} from '../../../client/components/ProgressOverlay';
import {Recaptcha} from '../../recaptcha/Recaptcha';
import {SubscribeMonthlyPromosParams} from '../net-results.interface';
import {subscribePromosModalValidation} from './SubscribePromosModal.validation';
import {useService} from '../../react/ServiceContext';

interface SubscribePromosModalProps {
    disableRecaptcha: boolean;
    onClose: () => void;
    show: boolean;
}

export const SubscribePromosModal = ({disableRecaptcha, onClose, show}: SubscribePromosModalProps) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [statusMessage, setStatusMessage] = useState(``);
    const formRef = useRef(null);
    const netResultsService: NetResultsService = useService(`netResultsService`);
    const recaptchaRef = useRef<ReCAPTCHA>();
    const useFormReturn = useForm<SubscribeMonthlyPromosParams>({mode: `onSubmit`, resolver: yupResolver(subscribePromosModalValidation)});

    /**
     * Resets form state when modal is closed
     */
    const onCloseHandler = () => {
        useFormReturn.reset();
        if (!disableRecaptcha) {
            recaptchaRef.current.reset();
        }
        onClose();
    };

    /**
     * Handles remote form submission
     */
    const submitForm = () => {
        formRef.current?.dispatchEvent(new Event(`submit`, {bubbles: true, cancelable: true}));
    };

    /**
     * Subscribes user to monthly promotions
     * @param subscribeMonthlyPromosParams
     */
    const subscribeMonthlyPromos = async (subscribeMonthlyPromosParams: SubscribeMonthlyPromosParams) => {
        setIsSubmitting(true);
        setStatusMessage(``);
        ProgressOverlay.activate();

        // If not disabled, add recaptcha to params
        if (!disableRecaptcha) {
            subscribeMonthlyPromosParams[`g-recaptcha-response`] = await recaptchaRef.current.executeAsync();
        }

        // Subscribe user to monthly promos
        netResultsService
            .subscribeMonthlyPromos(subscribeMonthlyPromosParams)
            .then(() => {
                ProgressOverlay.deactivate();
                setIsSubmitting(false);
                onCloseHandler();
            })
            .catch(() => {
                ProgressOverlay.deactivate();
                setIsSubmitting(false);
                setStatusMessage(`An error has occurred. Please try again`);
                if (!disableRecaptcha) {
                    recaptchaRef.current.reset();
                }
            });
    };

    /**
     * Template
     */
    return (
        <ContentModal
            footer={
                <>
                    <Button
                        modal={true}
                        modalOrderChangeButton1={true}
                        onClick={() => onCloseHandler()}
                        variant="outline-secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={isSubmitting}
                        modal={true}
                        modalOrderChangeButton2={true}
                        onClick={() => submitForm()}
                        type="submit"
                        variant="secondary"
                    >
                        Submit
                    </Button>
                </>
            }
            onClose={() => onCloseHandler()}
            show={show}
            title="Stay Updated"
        >
            <FormProvider {...useFormReturn}>
                <form
                    onSubmit={(event) => {
                        useFormReturn
                            .handleSubmit(subscribeMonthlyPromos)(event)
                            .catch((formError) => {
                                setStatusMessage(formError.message);
                            });
                    }}
                    ref={formRef}
                >
                    <div className="tw-mb-3">Enter your email to receive exclusive offers and expert tips from Imperial Supplies.</div>
                    <Input
                        className="maskPII"
                        error={useFormReturn?.formState?.errors?.emailAddress?.message}
                        label="Email Address"
                        name="emailAddress"
                        required
                        {...useFormReturn.register(`emailAddress`)}
                    />
                    {statusMessage && <div className="tw-text-sm">{statusMessage}</div>}
                    <Recaptcha
                        disableRecaptcha={disableRecaptcha}
                        ref={recaptchaRef}
                    />
                </form>
            </FormProvider>
        </ContentModal>
    );
};
